/* @charset "utf-8"; */
/* CSS Document */

// IMPORT - START

@import form-initialization-general
@import form-theme-sakina-svg

// IMPORT - END


// GENERAL - START

.CKEditorPromoCode
	div.cke_chrome
		border-radius: 10px
		border: 0px
		box-shadow: 0 0 3px rgb(0 0 0 / 15%)
	span.cke_voice_label
		display: none !important
		margin: 0px !important
	span.cke_top, span.cke_reset_all
		margin: 0px !important
		padding: 0px !important
		border-radius: 10px 10px 0px 0px
		box-shadow: 0 0 3px rgb(0 0 0 / 15%)
		span.cke_toolbar, span.cke_toolbar_last
			margin: 0px !important
			padding: 10px
			span.cke_toolbar_start
				display: none !important
			span.cke_toolgroup
				margin: 0px !important
				span
					margin: 0px !important
				span.cke_button_label
					display: none
				span#cke_14_label
					display: none !important
					margin: 0px !important
				span#cke_15_label
					display: none !important
					margin: 0px !important
		span.cke_toolbox
			margin: 0px !important
	div.cke_contents
		border-radius: 0px 0px 10px 10px
		box-shadow: 0 0 3px rgb(0 0 0 / 15%)
		div.cke_wysiwyg_div
			ul
				list-style: unset
			p
				font-family: inherit
				color: black

form
	div.DivForm
		h3
			margin-bottom: $SPACE_LITTLE
			font-size: $FONT_LABEL_H3
		fieldset
			legend
				font-size: $FONT_LABEL_LEGEND
				margin-top: $SPACE_LITTLE
				margin-bottom: $SPACE_LITTLE
			label
				font-size: $FONT_LABEL_GENERAL
				margin-bottom: $SPACE_NARROW
				margin-top: $SPACE_SMALL
				display: block
			label.LabelPreffix
				margin-right: $SPACE_LITTLE
			label.LabelSuffix
				margin-left: $SPACE_LITTLE
			span.SpanActiveStatus, i.IActiveStatus
				margin-right: $SPACE_MEDIUM
				vertical-align: middle
				display: inline-block
				padding-top: $SPACE_TINY
				padding-left: $SPACE_LITTLE
				padding-right: $SPACE_LITTLE
				padding-bottom: $SPACE_TINY
				font-size: $FONT_SPAN_TICKETSTATUS
				margin: 0px
				+setBorderRadius($RADIUS_TICKET_STATUS)
		span.SpanCustomerPhone
			display: flex !important
			select
				width: $WIDTH_INPUT_PHONE
				margin-right: $SPACE_LITTLE
	label.LabelAttachment
		width: $SIZE_ICON_LDPI
		height: $SIZE_ICON_LDPI
		+setBorderRadius(50%)
	div#divCardPerformance
		div.DivFormHorizontalContainer
			label.LabelPerformance
				font-size: $FONT_LABEL_SPAN
			label.LabelPerformancePackage
				padding-left: $SPACE_SMALL
				margin-bottom: 0px
				margin-top: $SPACE_LARGE
				font-size: $FONT_LABEL_SPAN
				color: $THEME_TERTIARY_DARK_COLOR
				font-weight: bold
			span.SpanPerformance
				font-size: $FONT_LABEL_H3
				color: black

div.DivBodyGeneral, div.DivVerifierGeneral, div.DivCenterGeneral
	header.HeaderGeneral
		h2
			font-size: $FONT_LABEL_H2
		h3
			font-size: $FONT_LABEL_H3
		span
			font-size: $FONT_LABEL_SUBTITLE
	div.DivForm
		h2, h3
			display: block
			margin-bottom: $SPACE_LITTLE
		h2
			margin-top: $SPACE_LITTLE
			font-size: $FONT_LABEL_H2
		h3
			margin-top: $SPACE_MEDIUM
			font-size: $FONT_LABEL_H3
		h3:first-of-type
			margin-top: 0px
		h4
			margin-top: $SPACE_MEDIUM
			font-size: $FONT_LABEL_H4
		h4:first-of-type
			margin-top: 0px
		label
			font-size: $FONT_LABEL_GENERAL
			margin-bottom: $SPACE_NARROW
			margin-top: $SPACE_SMALL
			display: block
			i
				font-size: 15px
				font-weight: 550
			i.textNormal
				font-style: normal !important
		label.mdc-floating-label
			line-height: auto
			margin-top: 0px
			width: 100%
			margin-bottom: 0px
		.mdc-text-field--filled .mdc-floating-label--float-above
			width: $WIDTH_LABEL_FLOATING_ABOVE
		div.DivHeaderHeadline
			div.DivSubHeaderHeadline
				label
					margin-top: 0
		span, p, textarea#spanIncidentNote
			font-size: $FONT_LABEL_PROPERTY
			margin-bottom: $SPACE_SMALL
			margin-top: $SPACE_NARROW
			display: block
		span, p
			word-break: break-all
		span.SpanRupiah
			word-break: normal
		.mat-mdc-form-field, .legend-wrap
			span
				font-size: inherit
				margin-bottom: 0px
				margin-top: 0px
				display: inline-block
				.legend-label-text
					word-break: break-word
					white-space: normal
		.legend-wrap
			.horizontal-legend
				li
					display: block
		dl
			dt, dd
				font-size: $FONT_LABEL_HEADERINFO
		div.DivHeaderHeadline
			div.DivHeaderStatus
				span
					font-size: $FONT_LABEL_SUBTITLE
		fieldset
			div.DivHeaderInfo
				dl
					dt, dd
						font-size: $FONT_DATALIST_HEADERINFO

div.DivProfileInfo
	div.DivProfileInfoDetails
		a.Underline
			text-decoration: underline
	div.DivProfilePhoto
		span.SpanChatProfileNameInitial
			margin: 0px
			font-family: $URL_FAMILYSECONDARY_PRIMARY
			color: white
			font-size: $FONT_SPAN_PROFILENAMEINITIAL
			display: block
			text-align: center

div.DivContainerForUpload
	div.DivContainerForAttachment
		p
			margin-top: 0px
			font-size: 16px
			margin-bottom: 0px
		label
			margin-bottom: 0px
			font-size: 14px
			margin-top: 5px
			font-style: italic

// GENERAL - END


// NAVIGATION - START

nav
	figure
		text-align: center
		span#spanProfileNameInitial
			font-size: $FONT_LABEL_PROFILENAMEINITIAL
			display: block
		figcaption
			span#spanProfileName, span#spanProfilePosition
				display: block
			span#spanProfileName
				font-size: $FONT_LABEL_PROFILENAME
			span#spanProfilePosition
				font-size: $FONT_LABEL_PROFILEPOSITION
	ul
		list-style-type: none
		li
			margin-top: $SPACE_LARGE
			margin-bottom: $SPACE_LARGE
	ul.ListNavigation
		li.ListItemNavigation
			font-size: $FONT_LIST_NAVIGATIONITEM
			ul.ListSubNavigation, ul.ListSubNavigationCalculatePremium
				li.ListItemSubNavigation
					font-size: $FONT_LIST_NAVIGATIONSUBITEM

// NAVIGATION - END


// SIGN IN - START

div#divBodySignIn
	header#headerSignIn
		h1, span
			display: block
		h1
			font-size: $FONT_LABEL_H1
			letter-spacing: $SPACE_LITTLE
			margin-bottom: -$SPACE_LITTLE
		span
			font-size: $FONT_LABEL_SPAN
	form
		div.DivNotRegistered
			text-align: center
			font-weight: 500

// SIGN IN - END


// DASHBOARD - START

div#divMainDashboard, div#divBodyDashboard
	div.DivHighlight
		margin-top: $SPACE_MEDIUM
		margin-bottom: $SPACE_MEDIUM
		text-align: center
		span.SpanHighlight
			padding-top: $SPACE_LITTLE
			padding-bottom: $SPACE_LITTLE
			padding-left: $SPACE_MEDIUM
			padding-right: $SPACE_MEDIUM
			margin: $SPACE_LITTLE
			min-width: $WIDTH_LABEL_HIGHLIGHT
			display: block
			+setBorderRadius($RADIUS_LABEL_HIGHLIGHT)
	div.DivDashboardEmeterai
		fieldset
			p
				font-weight: 500
				letter-spacing: 5px
				font-size: $FONT_DASHBOARD_EMATERAI

form div.DivForm fieldset, div.DivForm
	ul
		list-style: none
	div.DivFormHorizontalContainer, div.DivFormVerticalContainer
		div.DivListChart
			span
				font-size: $FONT_DASHBOARD_SPAN
			ul
				padding: $SPACE_LITTLE
				+setBorderRadius($RADIUS_DASHBOARD_LIST)
				li
					list-style: none
					margin-top: $SPACE_NARROW
					margin-bottom: $SPACE_LITTLE
					span
						font-size: $FONT_DASHBOARD_SPANLIST
						display: inline
					span:first-of-type
						+setBorderRadius($RADIUS_DASHBOARD_LIST)
						padding: $SPACE_TINY $SPACE_LITTLE
						margin: $SPACE_TINY

// DASHBOARD - END


// TABLE - START

div.DivTableContainer
	div.DivTableControl
		span.SpanTablePage
			margin-left: $SPACE_MEDIUM
			margin-right: $SPACE_MEDIUM
			font-size: $FONT_SPAN_TABLEPAGE
		label
			margin-right: $SPACE_MEDIUM
			white-space: nowrap
			font-size: $FONT_LABEL_GENERAL
	ul.ListTableThumbnails
		figure
			figcaption
				font-size: $FONT_LABEL_GENERAL
	ul.ListTableTicket, ul.ListTableConversation
		div.DivTicketItem, div.DivConversationGeneral
			span.SpanTicketTitle
				font-size: $FONT_LIST_TABLETITLE
			div.DivTicketSubItem, div.DivConversationItem
				div.DivTicketHeader, div.DivConversationHeader
					span
						display: block
					span.SpanTicketNumber, span.SpanConversationDate
						font-size: $FONT_DATA_TICKETLABEL
					span.SpanTicketTitle, span.SpanConversationTitle
						font-size: $FONT_LIST_TABLETITLE
						display: block
					span.SpanConversationDate
						text-align: right
					span.SpanTicketNumber
						display: inline-flex
						p
							margin-left: 5px
							border-left: solid 3px white
							padding-left: 5px
				div.DivTicketTail, div.DivConversationStatus
					span
						margin-right: $SPACE_MEDIUM
						vertical-align: middle
						display: inline-block
						padding-top: $SPACE_TINY
						padding-left: $SPACE_LITTLE
						padding-right: $SPACE_LITTLE
						padding-bottom: $SPACE_TINY
						font-size: $FONT_SPAN_TICKETSTATUS
						+setBorderRadius($RADIUS_TICKET_STATUS)
					span:first-of-type
						margin-left: 0px
						margin-bottom: $SPACE_LITTLE
					span:last-of-type
						margin-right: 0px
		li.LiConversationContainerLeft
			div.DivConversationGeneral
				div.DivConversationItem
					div.DivConversationHeader
						span.SpanConversationDate
							text-align: left
		dl
			dt, dd
				margin-left: 0px
				margin-right: 0px
				margin-bottom: $SPACE_THIN
				margin-top: $SPACE_THIN
				font-size: $FONT_DATA_TICKETLABEL
		li
			div.DivConversationProfile
				span.SpanChatProfileNameInitial
					font-size: $FONT_SPAN_PROFILENAMEINITIAL
					display: block
	table.TableListDetails
		font-size: 0.9em
		font-weight: 600
	table.TableListDetailsAttachment
		input[type=file]
			display: none
		label.LabelAttachment
			display: block
			width: $SIZE_ICON_LDPI
			height: $SIZE_ICON_LDPI
			+setBorderRadius(50%)
		label.LabelAttachment:first-of-type
			margin-left: auto
		label.LabelAttachment:last-of-type
			margin-right: auto
		div.DivMultipleLabelAttachment
			display: flex
			margin-top: -5px
			margin-bottom: -5px

// TABLE - END


// DYNAMIC CONTAINER - START

div.DivBodyGeneral, div.DivVerifierGeneral, div.DivCenterGeneral
	div.DivForm
		div.DivListControl
			h3
				margin: 0px
			div.DivListControlButton
				label
					margin-left: $SPACE_LITTLE
					margin-right: $SPACE_LITTLE
					font-size: $FONT_SPAN_TABLEPAGE
				label, input[type=button]
					display: inline-block

div.DivDynamicContainer
	form.FormInsertConversation
		div.DivConversationGeneral
			div.DivConversationHeader
				h3
					margin: 0
				div.DivConversationInfo
					div.DivConversationInfoDetails
						span
							margin: 0px
							text-align: right
			div.DivConversationContent
				div.DivAttachmentContainer
					label
						margin: 0px
			div.DivConversationProfile
				span.SpanChatProfileNameInitial
					margin: 0px
					font-family: $URL_FAMILYSECONDARY_PRIMARY
					color: white
					font-size: $FONT_SPAN_PROFILENAMEINITIAL
					display: block
					width: $SPACE_BIG
					height: $SPACE_ENORMOUS
					padding-top: $PADDING_SPAN_CONVERSATIONPROFILE
					text-align: center

div#divBodyClaimProposal
	div#divMainClaimProposalDetailApproval
		div.DivDynamicContainer
			margin-right: $SPACE_SMALL
			form
				margin-right: 0px
			div.DivFormListSurveyModel, div.DivFormListSurveyThirdPartyLiability
				label.LabelClaimLetterStatus, label.LabelClaimSurveyStatus
					a.NotVerified, a.Verified
						font-size: $FONT_LABEL_SPAN

// DYNAMIC CONTAINER - END


// LOADING - START

div#divCurtainLoading
	div#divLoadingMessage
		span#spanLoadingPercentage
			margin-bottom: $SPACE_HUGE
			font-size: $FONT_SPAN_LOADINGPERCENTAGE
		span
			font-size: $FONT_LABEL_GENERAL

// LOADING - END


// NOTIFICATION - START

div#divNotificationInstall, div#divNotificationInstallList
	span
		font-size: $FONT_LABEL_GENERAL
		font-weight: bold
	input#buttonNotificationClose
		font-size: $FONT_SIZE_CLOSE
	p#paragraphNotificationInstallIOS
		display: none
div#divNotificationInstallList
	span
		display: block
		margin-bottom: $SPACE_TINY

// NOTIFICATION - END


// APPROVAL - START

div.DivForm
	div.DivCard, div.DivCardFinish
		span#spanTitle
			font-size: 12pt
		span#spanName
			margin-top: -7.5px
			font-size: 16pt

// APPROVAL - END
	

// RETURN AMOUNT START

div.DivForm
	div.DivFormReturnAmount
		fieldset
			h3
				font-size: 12pt
				align-items: center


// RETURN AMOUNT END

// TD FOR OVERFLOW TEXT START

div.DivTableContainer
	table
		tbody
			tr
				td.TdWordingOverflow
					word-break: break-word
				input[type=button].ButtonRetry
					color: white
					font-size: 12px

div.DivMainAsideSearch
	div.DivTableContainer
		input[type=button].ButtonCancel
			background-color: #F7931E
			border-radius: 10%
			color: white
			font-size: 12px
			height: 30px
			padding: 10px
			width: 60px
			padding-top: 8px

// TD FOR OVERFLOW TEXT END


// E-MATERAI - START

div.DivButtonDashboardEmaterai
	p
		width: 100%
		font-size: 1.25em

div.DivSingleForm
	div.DivFormVerticalContainer
		label.LabelEmateraiSurveyStatus
			a
				font-size: $FONT_SIZE_LINK_EMATERAI
			span.SpanEmateraiSurveyStatus
				margin-top: 0px
				margin-bottom: 0px
				font-size: $FONT_SIZE_SPAN_EMATERAI
				margin-bottom: $SPACE_SMALL

// E-MATERAI - END


// E-BUDGETING - START

div.DivBodyEbudgeting
	div.DivEbudgetingSummary
		p
			margin: $SPACE_NONE
	div.DivEbudgetingDaashboadDescription
		ul
			li
				label
					margin: $SPACE_NONE
	form
		div.DivForm
			fieldset.FiledsetTitle
				span
					font-size: $FONT_SIZE_SPAN_EBUDGETING


div.DivBodyEbudgetingApproval
	div.DivForm
		label
			span.SpanStatusHistoryApproval
				font-size: $FONT_SIZE_SPAN_STATUS_APPROVAL

// E-BUDGETING - END