/* @charset "utf-8"; */
/* CSS Document */

// IMPORT - START

@import form-initialization-general

// IMPORT - END


// GENERAL - START

/* FORM - START */

form
	margin-bottom: $SPACE_HUGE
form.FormInsertConversation
	div.DivConversationGeneral
		div.DivConversationContent
			textarea
				border-bottom-left-radius: 0px
				border-bottom-right-radius: 0px
				resize: none
				margin-bottom: 0px
				padding-bottom: 0px
form.FormPerformance
	margin-bottom: 0px
	div.DivInformationPerformance
		width: 100%
		flex-wrap: wrap
		padding-left: $SPACE_SMALL
		div.DivForm
			margin: 0px
		span.SpanPerformance
			margin: 0px
	ngx-charts-pie-chart
		ngx-charts-chart
			.ngx-charts-outer
				width: auto !important
				height: 225px
				display: flex
				align-items: center
				.chart-legend .legend-labels
					background: transparent
				ngx-charts-legend.chart-legend
					padding: $SPACE_HUGE
				ul.legend-labels
					display: flex
					flex-direction: column
					flex-wrap: wrap
					height: 120px
					line-height: 130%
					align-items: center
				li.legend-label
					min-width: 110px
					margin: 2px
					font-size: 70%
				ngx-charts-legend-entry
					span
						display: flex !important
						align-items: center
						margin-bottom: $SPACE_THIN !important
						font-family: 'helveticaneuecyr_roman'
						color: #787878
						.legend-label-color
							height: 20px
							width: 20px

form.FormContainerPromoCode
	div.DivContainerAttachmentBanner
		div.DivContainerHorizontal
			margin-top: 0px
			img#imageBanner
				width: 24px
				margin-top: 15px
				margin-left: 10px
		div.DivContainerVertical
			margin-top: $SPACE_LITTLE
			margin-left: $SPACE_LITTLE
			label
				margin-top: $SPACE_THIN
				margin-bottom: 0px
				font-size: 0.7em
				font-style: italic
			span
				margin-top: $SPACE_THIN
				margin-bottom: 0px
				font-size: 0.8em
		img
			margin-top: $SPACE_MEDIUM
			max-width: 100%
	div.DivContainerPromoCode
		div.DivFormSingleDropdown
			select
				background-size: $SPACE_HUGE
		ng-select.NgSelectCoverageName
			margin-top: 13px
			.ng-select-container
				.ng-value-container
					.ng-placeholder
						height: 85%
						display: flex
						align-items: center
						padding-left: $SPACE_TINY
						color: #787878
						font-size: 1.2em
		label.LabelStatusPromoCode
			margin-top: 33px
		label.LabelPriority
			margin-top: 32px

div.DivForm, form div.DivForm fieldset
	input, textarea
		+setBoxSizing(border-box)
	input
		height: $HEIGHT_INPUT_SINGLELINE
	input, select, textarea, textarea.TextareaDisable
		margin-bottom: $SPACE_NARROW
		margin-top: $SPACE_NARROW
		width: 100%
		min-width: $WIDTH_INPUT_SQUARE
		padding-top: $PADDING_INPUT_EDGE
		padding-bottom: $PADDING_INPUT_EDGE
		padding-right: $PADDING_INPUT_SIDE
		padding-left: $PADDING_INPUT_SIDE
		font-size: $FONT_INPUT_GENERAL
		border-style: none
		+setBorderRadius($RADIUS_INPUT_GENERAL)
	input.InputToken
		min-width: $WIDTH_INPUT_SQUARE
		width: $WIDTH_INPUT_SQUARE
		text-align: center
	input::-webkit-outer-spin-button, input::-webkit-inner-spin-button
		+setAppearance(none)
		margin: 0px
	input[type=number]
		+setAppearance(textfield)
	mat-slider
		width: 100%
		height: 200px
	select
		min-height: $HEIGHT_SELECT_SINGLELINE
		background-repeat: no-repeat
		background-position: right
		+setAppearance(none)
	textarea
		height: $HEIGHT_TEXTAREA_GENERAL
	textarea.TextareaDescriptionHeader
		height: $HEIGHT_TEXTAREA_HEADER !important
		font-size: $FONT_INPUT_TEXTAREA_HEADERHEADLINE
		margin: 0px
	textarea:disabled, textarea.TextareaDisable
		height: $HEIGHT_TEXTAREA_READONLY
		resize: none
		padding: 0
		+setBorderRadius(0px)
	textarea.TextareaDefault
		resize: none
		height: inherit
	div.DivRadioButtonContainerVertical
		flex-direction: column
	div.DivRadioButtonContainer, div.DivRadioButtonContainerVertical, div.DivCheckBoxContainer
		label.LabelRadioButtonContainer, label.LabelCheckboxContainer
			line-height: 1.1
			display: flex !important
			align-items: center
			flex-direction: row
			gap: 10px
			input[type=radio], input[type=checkbox]
				border: 0.25em solid #787878
				-webkit-appearance: none
				appearance: none
				background-color: transparent
				font: inherit
				color: black
				transform: translateY(-0.075em)
				display: grid
				place-content: center
				padding: 5px 10px !important
				margin: 0px
				&::before
					content: ""
					transform: scale(0)
					transition: 100ms transform ease-in-out
					background-color: transparent
				&:checked
					&::before
						transform: scale(1)
		label.LabelRadioButtonContainer
			margin: 0px
			margin-left: 10px !important
			input[type=radio]
				width: 38px !important
				height: 38px !important
				border-radius: 50%
				&::before
					width: 22px
					height: 22px
					border-radius: 50%
					box-shadow: inset 23px 23px #F26F21
		label.LabelCheckboxContainer
			input[type=checkbox]
				width: 30px !important
				height: 30px !important
				min-height: 0px
				min-width: 0px
				border-radius: 8px
				&::before
					content: ""
					width: 15px
					height: 15px
					border-radius: 4px
					box-shadow: inset 15px 15px #F26F21
	div.DivRadioButtonContainer, div.DivRadioButtonContainerVertical
		display: flex
		margin: 0px
		min-height: 50px
		div.DivRadioButton
			margin-right: $SPACE_LITTLE
			width: $SIZE_INPUT_RADIO
			height: $SIZE_INPUT_RADIO
			padding: $SPACE_TINY
			border-style: solid
			border-width: $WIDTH_BORDER_INPUTTHICK
			+setBorderRadius(50%)
			display: flex
			align-items: center
			align-content: center
			justify-content: center
			input[type=radio]
				display: none
			label
				margin: 0px
				width: 0px
				height: 0px
				+setBorderRadius(50%)
			input[type=radio]:checked + label
				width: 100%
				height: 100%
		div.DivRadioButton + label
			margin-right: $SPACE_MEDIUM
		div.DivRadioButtonMerge
			display: flex
			align-items: center
	fieldset
		mat-form-field
			width: 100%
	div.DivCheckBoxContainer
		display: flex
		align-items: center
		div.DivCheckBox
			margin-right: $SPACE_LITTLE
			width: $SIZE_INPUT_CHECKBOX
			height: $SIZE_INPUT_CHECKBOX
			padding: $PADDING_INPUT_CHECKBOX
			border-style: solid
			border-width: $WIDTH_BORDER_INPUTTHICK
			+setBorderRadius($RADIUS_INPUT_CHECKBOXOUTER)
			display: flex
			align-items: center
			align-content: center
			justify-content: center
			input[type=checkbox]
				display: none
			label
				margin: 0px
				width: 0px
				height: 0px
				+setBorderRadius($RADIUS_INPUT_CHECKBOXINNER)
			input[type=checkbox]:checked + label
				width: 100%
				height: 100%
		div.DivCheckBox + label
			margin-right: $SPACE_MEDIUM
			margin-top: $SPACE_NARROW
			margin-bottom: $SPACE_NARROW
	mat-form-field
		margin: 0px
		padding: 0px
		width: 100%

	input.mat-input-element
			margin: 0px
			padding: 0px
			min-width: auto
div.DivMainAsideSearch, div.DivTransparantContainer
	div.DivDynamicContainer
		form
			width: auto
		input[type=button].ButtonSubmit
			width: $WIDTH_LINK_BUTTON_CONVERSATION
div.DivMainAsideSearch
	form
		margin-right: $SPACE_SMALL
		width: $WIDTH_LAYOUT_SIGNIN
		flex-shrink: 0
form.FormRadioButtonCenterContainer, form.FormRadioButtonDefaultContainer
	label.LabelRadioButtonVerticalContainer
		font-size: 0.9rem
		flex-direction: column
		margin-right: 10px
		input[type=radio]
			border: 0.3em solid #FFF
	label.LabelRadioButtonHorizontalContainer
		font-size: 1em !important
		flex-direction: row
		gap: 15px
		margin-right: 20px
		input[type=radio]
			border: 0.25em solid #787878
	label.LabelRadioButtonVerticalContainer, label.LabelRadioButtonHorizontalContainer
		line-height: 1.1
		display: flex !important
		align-items: center
		input[type=radio]
			-webkit-appearance: none
			appearance: none
			background-color: transparent
			margin: 0 0 5px 0
			font: inherit
			color: black
			width: 2.45em
			height: 2.45em
			border-radius: 50%
			transform: translateY(-0.075em)
			display: grid
			place-content: center
			&::before
				content: ""
				width: 1.35em
				height: 1.35em
				border-radius: 50%
				transform: scale(0)
				transition: 100ms transform ease-in-out
				box-shadow: inset 1em 1em #F7931E
				background-color: transparent
			&:checked
				&::before
					transform: scale(1)
		&:last-of-type
			margin: 0

form
	div.DivFormButtonCenter
		display: flex
		justify-content: center
		input[type=button]
			width: 60%

div.DivContainerForUpload
	input[type=file]
		display: none
	input[type=button]
		background-repeat: no-repeat

/* FORM - END */

/* THIRD PARTY - START */

div.DivForm, form div.DivForm fieldset
	.mat-form-field-appearance-fill, .mat-form-field-wrapper, .mat-form-field-flex, .mat-mdc-text-field-wrapper
		+setBorderRadius($RADIUS_INPUT_GENERAL)
		padding-right: $RADIUS_LAYOUT_CARD
		margin-top: $SPACE_TINY
		margin-bottom: 4px
	.mat-form-field-underline
		display: none
	.mat-form-field-wrapper
		padding-bottom: 0px
	.mat-form-field-appearance-fill
		width: 100%
	input.mat-date-range-input-inner, input.mat-datepicker-input
		height: 100%
		font: inherit
		border: none
		outline: none
		padding: 0
		margin: 0
		vertical-align: bottom
		text-align: inherit
		+setAppearance(none)
		width: 100%
	.mat-form-field
		margin-bottom: $SPACE_NARROW
		margin-top: $SPACE_NARROW
	.mat-mdc-icon-button
		width: 40px
		height: 40px
		display: inline-flex
		align-items: center
		justify-content: center
		padding: 0px

div.DivForm
	fieldset
		width: 100%
		padding: $SPACE_LITTLE
		margin-bottom: $SPACE_LITTLE
		margin-top: $SPACE_LITTLE
		border: none
		border-radius: $SPACE_LITTLE
	.ng-select
		margin-right: $SPACE_LITTLE
		width: 100%
	.ng-dropdown-panel
		width: 100%
		padding-left: $SPACE_LITTLE
	.ng-option
		margin-top: $SPACE_TINY
	.ng-select.custom .ng-clear-wrapper .ng-clear
		font-size: 0px

agm-map
	margin: 200px
	background-color: red
	height: 500px
	width: 100%

/* THIRD PARTY - END */

// GENERAL - END


// NAVIGATION - START

// NAVIGATION - END


// SIGN IN - START

// SIGN IN - END


// TABLE - START

div.DivTableContainer
	div.DivTableControl, div.DivConversationStatus
		select
			padding-top: $PADDING_INPUT_EDGE
			padding-bottom: $PADDING_INPUT_EDGE
			padding-right: $PADDING_INPUT_SIDE
			padding-left: $PADDING_INPUT_SIDE
			border-style: none
			background-repeat: no-repeat
			background-position: right
			+setAppearance(none)
	div.DivTableControl
		select
			margin-bottom: $SPACE_NARROW
			margin-top: $SPACE_NARROW
			width: 100%
			min-width: $WIDTH_INPUT_SINGLELINE
			min-height: $HEIGHT_SELECT_SINGLELINE
			font-size: $FONT_INPUT_GENERAL
			+setBorderRadius($RADIUS_INPUT_GENERAL)
	div.DivConversationStatus
		select
			font-size: $FONT_SELECT_SEVERITY
			width: $WIDTH_LABEL_CONVERSATIONSTATUS
			+setBorderRadius($RADIUS_TICKET_STATUS)
	div.DivConversationContent
		textarea
			+setBoxSizing(border-box)
			width: 100%
			min-width: $WIDTH_INPUT_SQUARE
			padding: $PADDING_INPUT_SIDE
			font-size: $FONT_INPUT_GENERAL
			border-style: none
			+setBorderRadius($RADIUS_INPUT_GENERAL)
	table.TableListPromoCode, table.TableListFAQ, table.TableListSettingTarget, table.TableListLanding, table.TableListHeader, table.TableListProduct
		thead, tbody
			tr
				td
					input[type=button].ButtonEdit, input[type=button].ButtonEditNoPadding
						cursor: pointer
						margin: 0
						background-size: 80px
					input[type=button].ButtonDeactiveDisable, input[type=button].ButtonDeactive, input[type=button].ButtonEditDisable
						cursor: pointer
						margin: 0 0 0 5px
						background-size: 36px
	table.TableListFAQ
		thead, tbody
			tr
				td
					input[type=button].ButtonEdit, input[type=button].ButtonEditNoPadding
						vertical-align: unset
						margin-right: 10px
	table.TableListNoTableControll
		margin-top: 0px

// TABLE - END


// E-BUDGETING - START

div.DivMainAsideSearch
	div.DivMainTable
		form
			div.DivButtonTableContainer
				display: flex
				justify-content: center
				column-gap: 2%
				input[type=button]
					width: 49%
					margin-top: $SPACE_NONE

div.DivTableContainer
	div.DivFormButtonContainer
		input[type=button]
			margin: $SPACE_NONE

// E-BUDGETING - END
