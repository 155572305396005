/* @charset "utf-8"; */
/* CSS Document */

// IMPORT - START

@import form-initialization-general
@import form-setup
@import form-theme-sakina-svg

// IMPORT - END


// GENERAL - START

form
	width: 100%
	div.DivForm
		padding: $SPACE_MEDIUM
		fieldset
			.divMatFormFieldPeriod
				width: 100%
			border: none
			padding: 0px
			margin-bottom: $SPACE_LITTLE
			margin-top: $SPACE_LITTLE
			div.DivInputHorizontalContainer
				margin: 0px
				padding: 0px
				+setBorderRadius(0px)
				display: flex
				justify-content: space-between
		fieldset:last-of-type
			margin-bottom: 0px
	div#divCardPerformance
		padding: 0px
		div.DivContainer
			div.DivFormHorizontalPerformanceLast
				margin-top: $SPACE_MEDIUM
	ul.UlListAsignee
		display: flex
		flex-flow: row wrap
		align-content: flex-start
		justify-content: space-between
		li
			box-sizing: border-box
			margin: 0
			width: 250px
			height: auto

div.DivForm
	div.DivPercentageProgress
		position: relative
		height: 40px
		width: 100%
		+setBoxSizing(border-box)
		+setBorderRadius($RADIUS_LAYOUT_FORM)
		div#divSubPercentageProgress
			position: absolute
			height: 40px
			width: 30%
			overflow: hidden
			+setBoxSizing(border-box)
			+setBorderRadius($RADIUS_LAYOUT_FORM)
		span
			position: relative
			color: white
			margin-left: 44%
			padding-top: 10px
			+setBoxSizing(border-box)
			+setBorderRadius($RADIUS_LAYOUT_FORM)

div.DivForm
	div.DivCard, div.DivCardFinish
		width: 100%
		height: 100%
		margin-bottom: 10px
		margin-top: 5px
		div.DivContainerIcon
			position: relative
			display: flex
			flex-direction: row
			justify-content: flex-end
			padding: 0px
			div.DivBorderImage
				display: flex
				justify-content: center
				align-items: center
				width: 32px
				height: 32px
				margin-right: 10px
				background-color: white
				+setBorderRadius(50%)
				img#imageQoute
					width: 26px
					height: 24px
					object-fit: cover
				img#imageApprove
					width: 26px
					height: 24px
					object-fit: cover

div.DivCard, div.DivCardFinish
	margin-top: $SPACE_SMALL
	margin-bottom: $SPACE_SMALL
	padding-left: $SPACE_MEDIUM
	padding-right: $SPACE_MEDIUM
	padding-bottom: $SPACE_MEDIUM
	padding-top: $SPACE_MEDIUM
	+setBoxSizing(border-box)
	+setBorderRadius($RADIUS_LAYOUT_FORM)

div.DivContainerIcon
	padding-left: 10px
	padding-right: 10px
	padding-bottom: 10px
	padding-top: 10px
	+setBoxSizing(border-box)
	+setBorderRadius($RADIUS_LAYOUT_FORM)

div.DivBodyGeneral, div.DivVerifierGeneral, div.DivCenterGeneral
	margin-top: $SPACE_HEADERGENERAL_TOP
	padding-bottom: $SPACE_HUGE

div.DivVerifierGeneral, div.DivCenterGeneral
	position: relative
	margin: $SPACE_ENORMOUS
	img#imageLogoHeader
		position: inherit !important
		top: 0px
		right: 0px
	div#divMainDashboard
		div.DivContainer
			margin-top: $SPACE_HUGE

div.DivMainGeneral, div.DivMainAsideSearch
	margin-top: $SPACE_MEDIUM
	width: 100%
	height: 100%

div.DivMainAsideSearch
	display: flex
	flex-wrap: nowrap
	justify-content: space-between
	align-content: stretch
	flex-basis: $WIDTH_LAYOUT_SIGNIN
	// align-items: baseline
	div.DivTableListDownload
		margin-left: 0px
		color: black
		table.TableListDetails
			max-width: inherit
			table-layout: inherit
			td:nth-child(1)
				width: 80%
				text-align: left
			td:nth-child(2)
				width: 10%
			td:nth-child(3)
				width: 10%
				padding-right: 0

div.DivContainerHorizontal, div.DivContainerVertical, div.DivContainerListHorizontal
	width: 100%
	height: 100%
	margin-top: $SPACE_MEDIUM
	display: flex
	flex-wrap: nowrap
	div.DivButtonAddDocument
		align-items: center
		margin-top: 0px
		label.LabelAttachment
			margin-right: 10px
			background: transparent
			input[type=button].ButtonAdd
				margin-top: 0px
div.DivButtonHorizontal
	margin-bottom: $SPACE_LITTLE
	margin-top: $SPACE_LITTLE

div.DivDivider
	height: 2px
	width: 100%
	background: $THEME_TERTIARY_LIGHT_COLOR

div.DivContainerHorizontal
	flex-direction: row
	div.DivContainer
		margin-left: $SPACE_SMALL
		margin-right: $SPACE_SMALL
		display: flex
		flex-direction: column
	div.DivContainer:first-of-type
		margin-left: 0px
	div.DivContainer:last-of-type
		margin-right: 0px
div.DivContainerVertical
	flex-direction: column

div.DivForm, div.DivFormBranch
	margin-top: $SPACE_SMALL
	margin-bottom: $SPACE_SMALL
	padding-left: $SPACE_MEDIUM
	padding-right: $SPACE_MEDIUM
	padding-bottom: $SPACE_MEDIUM
	padding-top: $SPACE_MEDIUM
	+setBoxSizing(border-box)
	+setBorderRadius($RADIUS_LAYOUT_FORM)

div.DivFormThreeHorizontalContainer
	display: grid
	grid-template-columns: 1fr 0.8fr 1fr

form div.DivForm fieldset, div.DivForm, div.DivCard, div.DivCardFinish
	div.DivFormHorizontalContainer, div.DivFormVerticalContainer, div.DivFormHorizontalContainerForReimbursement
		display: flex
		justify-content: space-between
		align-content: stretch
		align-items: baseline
		flex-wrap: wrap // nowrap
		div.DivListChart
			margin-top: $SPACE_MEDIUM
			align-self: flex-start
			ul
				height: $HEIGHT_CHART_LIST
				overflow-y: scroll
	div.DivFormHorizontalContainer
		flex-direction: row
		div.DivForm2Column
			margin-left: $SPACE_LITTLE
			margin-right: $SPACE_LITTLE
		div.DivForm2Column:first-of-type
			margin-left: 0px
		div.DivForm2Column:last-of-type
			margin-right: 0px
		div.DivForm2ColumnNoMargin
			margin-left: 0px
			margin-right: 0px
		div.DivForm2ColumnNoMargin:last-of-type:nth-of-type(odd)
			div.DivImageCapture
				width: 50%
	div.DivFormVerticalContainer
		flex-direction: column
		div.DivForm2Column
			margin-top: $SPACE_LITTLE
			margin-bottom: $SPACE_LITTLE
		div.DivForm2Column:first-of-type
			margin-top: 0px
		div.DivForm2Column:last-of-type
			margin-bottom: 0px
	div.DivForm2Column
		flex-basis: 1
		flex-grow: 1
		flex-shrink: 1
		width: 45%
		span.SpanActiveStatus, IActiveStatus
			width: $WIDTH_LABEL_POLICYGENERAL_STATUS
			text-align: center

div.DivForm:first-of-type
	margin-top: 0px

div.DivForm:last-of-type
	margin-bottom: 0px

form.FormRadioButtonCenterContainer
	justify-content: center
form.FormRadioButtonDefaultContainer
	justify-content: flex-start
	align-items: baseline
form.FormRadioButtonCenterContainer, form.FormRadioButtonDefaultContainer
	display: flex
	flex-direction: row
	margin: 0

div.DivLabelHorizontalContainer
	display: flex
	grid-template-columns: repeat(3, auto)
	justify-content: space-around
	label.LabelNumberContainer
		text-align: center
		span.SpanNumberValue
			margin: 20px auto 0 auto
			width: 50px
			height: 50px
			border-radius: 50%
			display: grid
			place-content: center
			color: white
			font-size: 1.8em
div.DivRoundButtonContainer
	display: flex
	justify-content: flex-end
	input[type=button]
		margin: 0px !important
	label
		margin-top: $SPACE_LITTLE !important

div.DivCenterButtonContainer
	display: flex
	align-items: center
	flex-direction: row
	width: 100%
	justify-content: center
	flex-wrap: nowrap
	gap: 11px

div#divBodyClaimProposal
	div#divMainClaimProposalDetailApproval
		div.DivDynamicContainer
			margin-right: $SPACE_SMALL
			form
				margin-right: 0px
			div.DivFormListSurveyModel, div.DivFormListSurveyThirdPartyLiability
				label.LabelClaimLetterStatus, label.LabelClaimSurveyStatus
					a
						border-radius: 5px
						padding: 2px 5px
	div.DivMainAsideSearch
		div.DivDynamicContainer, div.DivTableContainer
			margin-right: $SPACE_SMALL



div#divMainClaimProposalDetailApproval
	div.DivDynamicContainer
		table.TableOwnRetention
			width: 100%
			tr
				td.TdOwnRetentionnFirst
					width: 40%
				td.TdOwnRetentionnSecond
					width: 5%
				td.TdOwnRetentionnThird
					width: 5%
				td.TdOwnRetentionnFourth
					width: 20%
				td.TdOwnRetentionnTotal
					width: 30%

div.DivBodyGeneral
	div#divMainDashboard
		div.DivSingleForm
			width: 100%
			// margin: 0px ($SPACE_BIG*4)
			div.DivTableContainer
				height: auto

.FlexColumn
	display: flex
	flex-direction: column

.Width350
	width: 350px !important
	label
		text-align: center

.MarginLeft0
	margin-left: 0px !important

.MarginLeft10
	margin-left: 10px !important

.MarginTop20
	margin-top: 20px !important

.MarginTop35
	margin-top: 35px !important

.MarginBottom0
	margin-bottom: 0px !important

.MarginBottom20
	margin-bottom: 20px !important

.MarginBottom30
	margin-bottom: 30px !important

.MarginRight15
	margin-right: 15px !important

.PaddingRight15
	padding-right: 15px !important

div.DivContainerForUpload
	display: flex
	gap: $SPACE_MEDIUM
	align-items: center
	figure
		margin: 0
		img
			width: 36px
			height: auto
			cursor: pointer
	label.LabelAttachment
		margin-top: $SPACE_NARROW !important
	div.DivContainerForAttachment
		margin-left: 0px
		font-size: 16px

div.DivLegends
	color: $THEME_TERTIARY_SOFT_COLOR
	span
		padding: $SPACE_LITTLE $SPACE_SMALL
		+setBorderRadius($SPACE_TINY)

.FlexRow
	display: flex
	flex-direction: row

.FlexColumn
	display: flex
	flex-direction: column

.PaddingLeft0
	padding-left: 0px !important

.PaddingTop0
	padding-top: 0px !important

.PaddingBottom0
	padding-bottom: 0px !important

.MarginTop0
	margin-top: 0px !important

.MarginBottom0
	margin-bottom: 0px !important

// GENERAL - END


// NAVIGATION - START

nav
	position: relative
	width: $SIZE_LAYOUT_PROFILECONTAINER
	figure
		margin-bottom: $SPACE_BIG
		div, img#imageProfilePicture, span#spanProfileNameInitial
			border-style: solid
			+setBorderRadius(50%)
		div
			position: relative
			margin-left: auto
			margin-right: auto
			margin-bottom: $SPACE_MEDIUM
			width: $SIZE_LAYOUT_PROFILEFRAME
			height: $SIZE_LAYOUT_PROFILEFRAME
			padding: $SPACE_TINY
			border-width: $WIDTH_BORDER_PROFILETHIN
			img#imageProfilePicture, span#spanProfileNameInitial
				width: $SIZE_LAYOUT_PROFILEIMAGE
				height: $SIZE_LAYOUT_PROFILEIMAGE
				border-width: $WIDTH_BORDER_PROFILETHICK
			span#spanProfileNameInitial
				width: $WIDTH_LAYOUT_PROFILENAMEINITIAL
				height: $HEIGHT_LAYOUT_PROFILENAMEINITIAL
				padding-top: $SPACE_SMALL
				padding-bottom: $SPACE_SMALL
	ul.ListNavigation
		li.ListItemNavigation
			ul.ListSubNavigation, ul.ListSubNavigationCalculatePremium
				padding-left: $SPACE_SMALL

// NAVIGATION - END


// SIGN IN - START

div#divBodySignIn
	position: relative
	margin: auto
	width: $WIDTH_LAYOUT_SIGNIN
	height: 100%
	display: flex
	align-items: center
	justify-content: center
	flex-direction: column
	header#headerSignIn
		margin-bottom: $SPACE_BIG
		text-align: center
	form.FormSignInShow
		display: none
	form.FormSignInHide
		display: block
	div.DivButtonContainer, div.DivButtonContainerDisabled, div.DivButtonContainerVerifyOTP, div.DivButtonContainerVerifyOTPDisabled
		input[type=button]
			height: 50px
			border-radius: 25px
			width: 100%
			align-self: center
			text-align: center
			font-size: 1.1em
	div.DivButtonContainerDisabled, div.DivButtonContainerVerifyOTPDisabled
		input[type=button]
			background: linear-gradient(to right, #B4B4B4, #787878)
	div#divButtonContainerVerifyOTP
		display: none

div.DiviframeSignIn
	position: absolute
	display: flex
	flex-direction: column
	margin-top: $SPACE_MEDIUM
	margin-bottom: $SPACE_ENORMOUS
	bottom: $SPACE_LITTLE
	left: $SPACE_LITTLE
	margin: 0

// SIGN IN - END


// HOME - START

div#divBodyHome
	position: absolute
	top: 0px
	left: 0px
	right: 0px
	bottom: 0px
	display: flex
	justify-content: flex-start
	flex-direction: column
	perspective: 1000px
	main
		position: absolute
		padding: $SPACE_ENORMOUS
		top: 0px
		left: 0px
		right: 0px
		bottom: 0px
		+setTransformStyle(preserve-3d)

img#imageLogoHeader
	position: absolute
	width: $WIDTH_LAYOUT_LOGOHEADER
	height: $HEIGHT_LAYOUT_LOGOHEADER
	object-fit: cover

div#divBodyHome
	img#imageLogoHeader
		top: $SPACE_ENORMOUS
		right: $SPACE_ENORMOUS

// HOME - END


// ABOUT - START

img#imageLogoAbout
	display: block
	margin-left: auto
	margin-right: auto
	width: $WIDTH_LAYOUT_LOGOABOUT
	height: $HEIGHT_LAYOUT_LOGOABOUT
	object-fit: cover

// ABOUT - END


// DASHBOARD -  START

.ngx-charts-outer
	height: auto !important

.ChartBar
	margin-top: $SPACE_MEDIUM
	margin-bottom: $SPACE_MEDIUM

div#divMainDashboard
	div.DivDashboardEmeterai
		display: flex
		justify-content: space-evenly
		fieldset
			width: auto
			p
				width: 300px
				height: 150px
				margin-left: 0px
				justify-content: center
				align-items: center
				display: flex
				border-radius: 10px

// DASHBOARD - END


// DYNAMIC CONTAINER - START

div.DivDynamicContainer
	margin-left: $SPACE_SMALL
	width: 100%
	height: 100%
	div.DivTableContainer
		margin-left: 0px !important
	div.DivSubDynamicContainer
		margin-top: $SPACE_MEDIUM
		form
			width: 100%
			margin-right: 0px
	div.DivListControl
		display: flex
		justify-content: space-between
		align-items: center
	form:last-of-type
		margin-right: 0px
	form.FormInsertConversation
		margin-bottom: 0px
		div.DivConversationGeneral
			display: flex
			flex-direction: column
			div.DivConversationHeader
				display: flex
				justify-content: space-between
				div.DivConversationInfo
					display: flex
					flex-direction: row
					div.DivConversationInfoDetails
						display: flex
						flex-direction: column
						justify-content: center
			div.DivConversationChatType
				width: 25%
			div.DivConversationContent
				display: flex
				flex-direction: column
				margin-top: $SPACE_LITTLE
				div.DivAttachmentContainer
					width: 100%
					box-sizing: border-box
					display: flex
					align-items: center
					border-bottom-left-radius: $RADUIS_LINK_BUTTONCONVERSATION
					border-bottom-right-radius: $RADUIS_LINK_BUTTONCONVERSATION
					margin-top: 0px
					padding-left: $SPACE_LITTLE
					padding-bottom: $SPACE_LITTLE
					height: $HEIGHT_LAYOUT_BUTTONATTACHMENTCONTAINER
			div.DivConversationProfile
				margin-left: $SPACE_MEDIUM
				+setBorderRadius(50%)

div.DivTransparantContainer
	padding: $SPACE_HUGE
	margin-top: $SPACE_MEDIUM
	+setBorderRadius($RADIUS_LAYOUT_FORM)

// DYNAMIC CONTAINER - END


// EXTRA - START

div.DivFormHorizontalContainer
	div.DivForm2Column
		div.DivImageCapture
			img
				display: block
				width: 95%
				height: 95%
				border-radius: 10px
			video
				display: block
				width: 95%
				height: 90vh

// EXTRA - END


// TABLE - START

div.DivTableContainer
	margin-left: $SPACE_SMALL
	width: 100%
	height: 100%
	div.DivTableControl
		display: flex
		justify-content: space-between
		align-items: center
		div.DivTableSubControl
			display: flex
			justify-content: space-between
			align-items: center
	ul.ListTableThumbnails, table, ul.ListTableTicket, ul.ListTableConversation
		margin-top: $SPACE_LARGE
		margin-bottom: $SPACE_LARGE
	ul.ListTableThumbnails, ul.ListTableTicket, ul.ListTableConversation
		display: flex
		list-style-type: none
		+setBorderRadius($RADIUS_LAYOUT_FORM)
		li
			margin: 0px
			padding: $SPACE_LITTLE
	ul.ListTableThumbnails
		justify-content: flex-start
		align-content: stretch
		align-items: baseline
		flex-wrap: wrap
		li
			width: $WIDTH_TABLE_THUMBNAILSFIGURE
			// height: $HEIGHT_TABLE_THUMBNAILSFIGURE
			height: auto
			figure
				position: relative
				margin-left: auto
				margin-right: auto
				text-align: center
				img
					width: $SIZE_TABLE_THUMBNAILSICON
					height: $SIZE_TABLE_THUMBNAILSICON
					margin-bottom: $SPACE_LITTLE
					border: none
				figcaption
					word-wrap: normal
					white-space: normal
					word-break: break-all
	table
		border-collapse: unset
		width: 100%
		thead, tbody
			tr
				td, th
					padding-top: $SPACE_MEDIUM
					padding-left: $SPACE_LITTLE
					padding-right: $SPACE_LITTLE
					padding-bottom: $SPACE_MEDIUM
					word-wrap: normal
					white-space: normal
					word-break: normal
					figure
						img
							width: $SIZE_TABLE_THUMBNAILSICONSMALL
							height: $SIZE_TABLE_THUMBNAILSICONSMALL
					span.SpanScore
						padding: 10px 14px
						border-radius: 50%
				td:first-of-type, th:first-of-type
					padding-left: $SPACE_MEDIUM
				td:last-of-type, th:last-of-type
					padding-right: $SPACE_MEDIUM
			tr.TableRowSelected
				background-color: #F26F21
				border: none
				gap: 0px
				cursor: pointer
		thead
				th:first-of-type
					+setBorderRadiusLeft($RADIUS_TABLE_GENERAL)
				th:last-of-type
					+setBorderRadiusRight($RADIUS_TABLE_GENERAL)
		tbody
			tr:first-of-type
				td:first-of-type
					+setBorderRadiusTopLeft($RADIUS_TABLE_GENERAL)
				td:last-of-type
					+setBorderRadiusTopRight($RADIUS_TABLE_GENERAL)
			tr:last-of-type
				td:first-of-type
					+setBorderRadiusBottomLeft($RADIUS_TABLE_GENERAL)
				td:last-of-type
					+setBorderRadiusBottomRight($RADIUS_TABLE_GENERAL)
	table.TableListDetails
		padding: 0px
		margin-top: $SPACE_LITTLE
		margin-bottom: 0px
		max-width: $WIDTH_TABLE_ATTACHMENT
		table-layout: fixed
		td
			padding: 0px
			figure
				margin: 0px
				img
					margin-top: $SPACE_TINY
		td:first-of-type
			text-align: right
			padding-left: 0px
		td:nth-child(1)
			width: $WIDTH_TABLE_ATTACHMENTSMALL
		td:nth-child(2)
			width: $WIDTH_TABLE_ATTACHMENTBIG
			overflow: hidden
			text-overflow: ellipsis
		td:nth-child(3)
			width: $WIDTH_TABLE_ATTACHMENTMEDIUM
		td:nth-child(4)
			width: $WIDTH_TABLE_ATTACHMENTSMALL
			padding-right: 0px
	table.TableListDetailsAttachment
		td:last-of-type
			position: relative
			flex-flow: column wrap
			align-items: center
			div.DivMultipleLabelAttachment
				justify-content: center
				gap: $SPACE_TINY
				margin-top: $SPACE_TINY
				margin-bottom: $SPACE_TINY
	table.TableListReportMonitoring, table.TableListPromoCode
		display: block
		border-collapse: unset
		overflow-x: scroll
		thead, tbody
			tr
				th, td
					min-width: 200px
				th:first-of-type, td:first-of-type
					padding-left: 10px
				th:last-of-type, td:last-of-type
					padding-right: 10px
	table.TableListReportMonitoring, table.TableListPromoCode
		display: block
		border-collapse: unset
		overflow-x: scroll
		thead, tbody
			tr
				th, td
					min-width: 200px
	table.TableListLanding
		table-layout: fixed
		thead, tbody
			tr
				th, td
					word-wrap: break-word
				th:nth-of-type(1), td:nth-of-type(1)
					width: 40%
				th:nth-of-type(2), td:nth-of-type(2)
					width: 50%
				th:nth-of-type(3), td:nth-of-type(3)
					width: 10%
	ul.ListTableTicket, ul.ListTableConversation
		flex-direction: column
		li
			width: 100%
			padding-top: $SPACE_SMALL
			padding-left: $SPACE_MEDIUM
			padding-right: $SPACE_MEDIUM
			padding-bottom: $SPACE_SMALL
			display: flex
			align-items: center
			+setBoxSizing(border-box)
			img
				margin-right: $SPACE_MEDIUM
				width: $SIZE_ICON_LDPI
				height: $SIZE_ICON_LDPI
			div.DivTicketItem, div.DivConversationGeneral
				display: flex
				flex-direction: column
				flex-grow: 1
				div.DivTicketSubItem, div.DivConversationItem, div.DivTicketContentItem
					display: flex
					justify-content: space-between
					dl
						margin: 0px
						flex-grow: 1
						flex-basis: 0
					div.DivConversationStatus
						display: flex
						align-items: flex-start
					div.DivConversationHeader
						display: flex
						flex-flow: row wrap
						align-items: center
						justify-content: flex-start
						div.DivConversationProfile
							+setBorderRadius(50%)
							span.SpanChatProfileNameInitial
								width: $SPACE_BIG
								height: $SPACE_BIG
								display: flex
								align-items: center
								justify-content: center
						div.DivConversationSubHeader
							span.SpanConversationTitle
								max-width: 200px
					div.DivTicketTail
						span.SpanTicketAlert
							width: 7px
							height: 17px
							background-color: red
							margin-bottom: 0
							+setBorderRadius(50%)
							border-style: solid
			div.DivTicketSubItem:first-of-type, div.DivConversationItem:first-of-type
				margin-bottom: $SPACE_TINY
			div.DivTicketSubItem:last-of-type, div.DivConversationItem:last-of-type
				margin-top: $SPACE_TINY
		li:first-of-type
			+setBorderRadiusTop($RADIUS_LAYOUT_FORM)
		li:end-of-type
			+setBorderRadiusBottom($RADIUS_LAYOUT_FORM)
	ul.ListTableConversation
		li
			+setBorderRadius($RADIUS_LAYOUT_FORM)
		li.LiConversationContainerRight, li.LiConversationContainerLeft
			margin-top: $SPACE_MEDIUM
			width: 80%
			+setBoxSizing(border-box)
		li.LiConversationContainerRight
			align-self: flex-end
			div.DivConversationItem
				div.DivConversationHeader
					span
						text-align: right
					div.DivConversationProfile
						margin-left: $SPACE_MEDIUM
						margin-right: 0px
		li.LiConversationContainerLeft
			align-self: flex-start
			flex-direction: row-reverse
			div.DivConversationItem
				flex-direction: row-reverse
				div.DivConversationHeader
					flex-direction: row-reverse !important
					span.SpanConversationDate
						text-align: left
					div.DivConversationProfile
						margin-left: 0px
						margin-right: $SPACE_MEDIUM
		li:first-of-type
			margin-top: 0px

div.DivTableAchievement
	table
		tbody
			tr
				td
					text-align: center

div.DivSubDynamicContainer
	div.DivTableContainer
		margin-left: 0px
	table.TableSummary
		width: 100%
		thead, tbody
			tr:nth-child(odd)
				th, td
					width: 14%
				td
					background-color: rgba(255, 255, 255, 0.2) !important
			tr:nth-child(even)
				th, td
					width: 14%
				td
					background-color: rgba(255, 255, 255, 0.1) !important

div.DivCenterGeneral
	div.DivCenterContainer
		div.DivTableContainer
			margin-left: 0px
	div.DivDynamicContainer
		margin-left: 0px

div.DivMultipleButton
	display: flex
	flex-direction: row
	justify-content: center
	column-gap: 15px

div.DivNoMargin
	margin-left: 0px

div.DivContainerListHorizontal
	div.DivTableContainerHorizontal
		div.DivTableContainer
			div.DivListControl
				div.DivNavigationControlButton
					display: flex
					flex-wrap: wrap
					justify-content: space-between
	div.DivTableContainerColumn
		width: 100%

div.DivTableScrollHorizontal
	overflow-x: auto

// TABLE - END


// LOADING - START

div#divCurtainLoading
	position: fixed
	top: 0px
	left: 0px
	right: 0px
	bottom: 0px
	display: none
	z-index: 10
	div#divLoadingBar
		width: 0%
		height: $HEIGHT_LOADING_BAR
		display: none
	div#divLoadingMessage, img#imageLoadingCircle
		display: flex
		flex-direction: column
		justify-content: center
		align-items: center
	div#divLoadingMessage
		width: 100%
		height: 100%
		img#imageLoadingCircle
			width: 100px
			height: 100px
			background-color: none

// LOADING - END


// NOTIFICATION - START

div#divNotificationInstall, div#divNotificationInstallList
	display: flex
	padding: $SPACE_LITTLE
	margin-bottom: $SPACE_LITTLE
	box-sizing: border-box
div#divNotificationInstall
	height: 100px
	justify-content: space-between
	align-items: center
	width: 100%
	position: fixed
	left: 0px
	bottom: 0px
	right: 0px
div#divNotificationInstallList
	flex-direction: column
	height: auto
	+setBorderRadius($RADIUS_INPUT_GENERAL)

// NOTIFICATION - END

div#divBodyDashboard
	div#divMainDashboard
		width: 100%
		height: 100%
		margin-top: $SPACE_MEDIUM
	div#divMainDashboard
		display: flex
		flex-wrap: nowrap
	div.DivCenterContainer
		margin-top: $SPACE_HUGE
		display: block
		width: 75%
		position: relative
		margin-left: auto
		margin-right: auto
		div.DivForm
			div.DivHeaderHeadline
				display: flex
				justify-content: space-between
				div.DivHeaderStatus
					display: flex
					align-items: flex-start
					justify-content: space-between
					span
						text-align: center
						margin-left: $SPACE_LITTLE
						margin-right: $SPACE_LITTLE
						margin-top: 0px
						padding: $SPACE_LITTLE
						+setBorderRadius($RADIUS_BUTTON_GENERAL)
					span:last-of-type
						margin-right: 0px
				div.DivSubHeaderHeadline
					label
						margin: 0
					h2
						margin-top: 5px
						margin-bottom: 5px
			fieldset
				margin-top: 0px
				div.DivForm2Column
					div.DivHeaderInfo
						margin-top: $SPACE_LITTLE
						display: flex
						justify-content: space-between
						dl
							margin: 0px
							flex-grow: 1
							flex-basis: 0
							dd
								margin: 0
				div.DivFormHorizontalContainer
					div.DivHeaderList
						align-self: flex-start
						ul
							li
								padding: 5px 10px
								width: fit-content
								margin-right: 0
								margin-bottom: 5px
								margin-top: 5px
								span
									margin: 0
								+setBorderRadius(9px)
							li:first-of-type
								margin-top: 0px
							li:last-of-type
								margin-bottom: 0px

div.DivProfileGeneral
	display: flex
	justify-content: space-between
	div.DivProfileInfo
		display: flex
		flex-direction: row-reverse
		div.DivProfileInfoDetails
			display: flex
			flex-direction: column
			justify-content: center
		div.DivProfilePhoto
			margin-right: $SPACE_SMALL
			+setBorderRadius(50%)
			span.SpanChatProfileNameInitial
				width: $SPACE_BIG
				height: $SPACE_ENORMOUS
				padding-top: $PADDING_SPAN_CONVERSATIONPROFILE
				display: flex
				justify-content: center

// CALCULATE PREMI FORM - START

form.CalculateForm
	width: 50%
	margin-left: auto
	margin-right: auto
	div.DivForm
		padding: $SPACE_MEDIUM
		fieldset
			border: none
			padding: 0px
			margin-bottom: $SPACE_LITTLE
			margin-top: $SPACE_LITTLE
			div.DivInputText
				margin: 0px
				padding: 0px
				+setBorderRadius(0px)
				display: flex
				align-items: flex-end
				span.SpanRupiah
					margin-right: 30px
		fieldset:last-of-type
			margin-bottom: 0px

// CALCULATE PREMI - END


// PRODUCT INFORMATION - START

div.DivTableContainer
	ul.ListTableThumbnails
		li
			div.DivButtonNextCalculate
				display: flex
				justify-content: center
				div.DivButtonHide
					display: none

// PRODUCT INFORMATION - END

/* THIRD PARTY  START */

// SINGLE DROPDOWN - START

div.DivFormSingleDropdown
	.ng-select
		width: 100%
		.ng-select-container
			height: 40px
			width: 100%
			border-radius: $SPACE_LITTLE
			background-repeat: no-repeat
			background-position: right
			background-size: $SPACE_HUGE + 6
			.ng-value-container
				flex-wrap: wrap
				width: 100%
				.ng-value
					padding-right: $SPACE_TINY
					font-size: 14px
					margin: $SPACE_SMALL 0px $SPACE_SMALL $SPACE_LITTLE
					border-radius: $SPACE_TINY
					margin-right: $SPACE_TINY
					display: flex
					line-height: 0px
					align-items: center
					.ng-value-label
						margin-left: 5px
						white-space: normal
						line-height: initial
						margin-top: 0px
				.ng-input>input
					padding-left: $SPACE_LITTLE
	.ng-clear-wrapper
		margin-right: $SPACE_LARGE
		height: $SPACE_MEDIUM
	.ng-dropdown-panel
		.ng-dropdown-panel-items
			min-height: $SPACE_HUGE
			.ng-option
				box-sizing: border-box
				cursor: pointer
				display: block
				margin-bottom: -$SPACE_SMALL
				margin-left: $SPACE_LITTLE

div.DivFormLongTextDropdown
	.ng-select
		width: 100%
		white-space: normal
		.ng-dropdown-panel
			margin-left: 0px
		.ng-select-container
			height: 60px
			width: 100%
			border-radius: $SPACE_LITTLE
			background-repeat: no-repeat
			background-position: right
			background-size: $SPACE_HUGE + 6
			.ng-value-container
				flex-wrap: wrap
				width: 100%
				display: flex
				align-items: center
				.ng-value
					padding-right: $SPACE_TINY
					font-size: 14px
					margin: $SPACE_SMALL 0px $SPACE_SMALL $SPACE_LITTLE
					border-radius: $SPACE_TINY
					margin-right: $SPACE_TINY
					display: flex
					line-height: 0px
					align-items: center
					height: fit-content
					.ng-value-label
						margin-left: 5px
						white-space: normal
						line-height: initial
						margin-top: 0px
					span
						margin-bottom: 0px
				.ng-input>input
					padding-left: $SPACE_LITTLE
					margin-top: 0px
	.ng-clear-wrapper
		margin-right: $SPACE_LARGE
		height: $SPACE_MEDIUM
	.ng-dropdown-panel
		margin-left: 0px
		.ng-dropdown-panel-items
			.ng-option
				box-sizing: border-box
				cursor: pointer
				display: block
				margin-bottom: -$SPACE_SMALL
				margin-left: $SPACE_LITTLE

// SINGLE  DROPDOWN - END

// MULTIPLE DROPDOWN - START

div.DivFormMultipleDropdown
	.ng-select
		width: 100%
		.ng-select-container
			width: 100%
			border-radius: $SPACE_LITTLE
			background-repeat: no-repeat
			background-position: right
			background-size: $SPACE_HUGE
			.ng-value-container
				flex-wrap: wrap
				width: 100%
				padding: 5px
				.ng-value
					padding-right: $SPACE_TINY
					font-size: 14px
					margin: $SPACE_TINY
					border-radius: $SPACE_TINY
					display: flex
					line-height: 0px
					align-items: center
					height: fit-content
					.ng-value-icon
						display: inline-block
						padding: $SPACE_TINY $SPACE_TINY
						padding-top: 10px
						background-color: #F7931E
					.ng-value-icon.left
						// margin-right: $SPACE_TINY
						margin-left: -$SPACE_LITTLE
						margin-top: 0px
						margin-bottom: 0px
						border-radius: 50%
						height: 20px
						border: none
						opacity: 0%
					.ng-value-label
						margin-left: 5px
						white-space: normal
						line-height: initial
						padding-top: 10px
						padding-bottom: 10px
					span
						margin-bottom: 0px
						margin-top: 0px
				.ng-value::before
					content: "-"
					width: 10px
					height: 10px
					margin-left: 5px
					margin-right: -10px
					background-color: #F7931E
					+setBorderRadius($SPACE_LITTLE)
					color: white
					text-align: center
					padding-left: 5px
					padding-right: 5px
					padding-top: 8px
					padding-bottom: 2px
					font-size: 2em
				.ng-input>input
					margin-left: $SPACE_TINY
		.ng-clear-wrapper
			position: relative
			margin-top: auto
			margin-bottom: auto
			margin-right: 25px
			padding: 0px
			span
				margin-bottom: 0px
				margin-top: 0px
				padding-left: 7px
	.ng-dropdown-panel
		.ng-dropdown-panel-items
			.ng-option
				box-sizing: border-box
				cursor: pointer
				display: block
				margin-bottom: -$SPACE_SMALL
				margin-left: $SPACE_LITTLE

// MULTIPLE DRODOWN - END

// NGX-MAT-TIMEPICKER START

div.DivForm, form div.DivForm fieldset
	table.ngx-mat-timepicker-table
		tbody.ngx-mat-timepicker-tbody
			td.ngx-mat-timepicker-spacer
				padding-top: $SPACE_LITTLE
			div, mat-form-field
				margin: 0px
				padding: 0px
				tr
					td
						height: 35px

// NGX-MAT-TIMEPICKER END

/* THIRD PARTY  END */

/* EMATERAI - START */

form
	div.DivForm
		div.DivMatFormFieldYearAndMonth
			.mat-form-field
				width: 100%
		div.DivRadioButtonContainer
			div.DivSubRadioButtonContainer
				display: flex
				align-items: center

/* EMATERAI - END */


// Reimbursment - Start

div#divBodyDashboard
	form.FormReimbursement
		width: 25%
	div.DivTableReimbursment
		width: 100%


div.DivFormHorizontalContainerForReimbursement
	div.DivForm2Column
		flex: 1
		p
			font-size: 14px !important
			margin-top: -10px !important
			font-family: $URL_FAMILYPRIMARY_PRIMARY !important

div.DivForm
	div.DivRadioButtonContainer
		input#checkboxReimbursementDetail
			min-width: 15px
		label
			margin-left: 10px

// Reimbursment - End


// CONTAINER FILE ATTACHMENT DETAIL START

div.DivForm
	fieldset
		p
			font-size: $SPACE_LITTLE
	div.DivContainerAttachedment
		ul
			display: flex
			flex-wrap: wrap
			li
				margin-left: $SPACE_MEDIUM
				margin: 0px
				padding: $SPACE_LITTLE
				figure
					margin: 0px
					padding: 0px
				figcaption
					width: 100px
					word-break: break-word
					img
						width: 100px
				div.DivButton
					display: flex
					justify-content: center

// CONTAINER FILE ATTACHMENT DETAIL END


// REPORT MONITORING LIST - START

div#divMenuAllProductListContainer
	display: none
	div.DivTableContainerHorizontal
		text-align: center
		h3
			text-align: left !important

form
	div.DivForm2ColumnButton
		display: flex
		gap: 10px
		input[type=button]
			margin: 10px 0
			font-size: 1em

// REPORT MONITORING LIST - END


// PROMO CODE - DISCOUNT LIST - START

div#divStatusDiscount
	div.DivForm
		h3
			margin-bottom: 30px
		div.DivFormHorizontalContainer
			justify-content: space-around
			div.DivFormVerticalContainer
				align-items: center
				label
					margin-bottom: 0
				div.DivBubleStatus, div.DivBubleStatusActive, div.DivBubleStatusNonActive, div.DivBublePublic
					width: 350px
					height: 70px
					display: flex
					justify-content: center
					align-items: center
					border-radius: 20px
					label
						margin: 0
						color: $THEME_TERTIARY_SOFT_COLOR
						font-weight: bold
						font-size: 2em
				div.DivBubleStatusActive
					background-color: $THEME_INDICATOR_SUCCESS_NORMAL_COLOR
				div.DivBubleStatusNonActive
					background-color: $THEME_INDICATOR_FAIL_NORMAL_COLOR
				div.DivBublePublic
					background-color: $THEME_CONVERSATION_PUBLIC_COLOR

// PROMO CODE - DISCOUNT LIST - END


// RADIO BUTTON VERTICAL - START

div.DivContainerLabelRadioVertical
	display: flex
	flex-direction: column
	label
		margin-bottom: 15px !important
	div.DivRadioButtonContainer
		gap: 50px !important

// RADIO BUTTON VERTICAL - END


// INFORMATION CHECK - START

div.DivInformationCheck
	display: flex
	img.ImgCheck
		width: 20px
		height: 20px
		margin-top: 8px
		margin-right: 10px
		background-size: cover

// INFORMATION CHECK - END


/* E-MATERAI - START */

div.DivBodyEmaterai
	display: flex
	flex-direction: column

div.DivButtonDashboardEmaterai
	display: flex
	justify-content: space-between
	margin-top: 20px

div.DivSingleForm
	div.DivFormVerticalContainer
		label.LabelEmateraiSurveyStatus
			a
				+setBorderRadius($SPACE_TINY)
				padding: 2px 25px


// PDF Viewer - Start

div.DivTableContainer
	#divPdf
		margin: auto
		position: relative
		#divBox
			width: 80px
			height: 80px
			color: rgba(0, 0, 0, 0.87)
			cursor: move
			display: flex
			justify-content: center
			align-items: center
			background-size: contain
			background-repeat: no-repeat
			background-position-x: center
			position: relative
			z-index: 1
		.DivPdfViewer
			position: absolute
			display: block
			height: 100%
			width: 100%
			.ng2-pdf-viewer-container
				height: 100% !important
				overflow-x: unset !important
				.pdfViewer
					height: 100% !important
					.page
						height: 100% !important
						width: 100% !important
						margin: 0px !important
						border: 0px !important
						canvas
							width: 100% !important
							height: 100% !important
						.canvasWrapper
							width: 100% !important
							height: 100% !important
						.textLayer
							width: 100% !important
							height: 100% !important
	div.DivTableControlMaterai
		margin-bottom: 1em
		div.DivTableSubControlMaterai
			display: flex
			align-items: center
			margin: auto
			width: 250px
			input[type=button]
				margin-top: 0
			input[type=button]:first-of-type
				margin-top: 0
			input[type=button]:last-of-type
				margin-bottom: 0

div.DivTransparent
	margin: auto !important
	background-color: rgba(255, 255, 255, 0) !important

// PDF Viewer - End

/* E-MATERAI - END */


// E-BUDGETING - START

div#divBodyDashboard
	div#divMainDashboard
		div.DivSingleForm
			form
				div.DivButtonContainer
					display: flex
					flex-wrap: wrap
					justify-content: center
					column-gap: 2%
					margin-top: $SPACE_MEDIUM
		div.DivMainAsideContent
			width: 100%
			form
				width: 100%

form.FormTransparent
	div.DivForm
		padding: $SPACE_NONE
		margin-top: $SPACE_LARGE
		display: flex
		flex-wrap: wrap
		column-gap: 1%
		fieldset
			width: 35%
			padding-left: $SPACE_NONE

div.DivTableContainer
	form
		margin: $SPACE_NONE
		div.DivFormButtonContainer
			justify-content: center
			column-gap: $SPACE_LITTLE


div.DivBodyEbudgeting
	div.DivForm
		div.DivFormHorizontalContainer
			justify-content: space-evenly
			text-align: center
		div.DivFormContainerBudgetAllocation
			display: flex
			flex-wrap: wrap
			column-gap: 4%
			row-gap: $SPACE_LARGE
			align-items: center
			padding: $SPACE_LARGE
			div.DivContainerBudgetAllocation
				display: flex
				justify-content: center
				column-gap: $SPACE_HUGE
				flex-wrap: wrap
		div.DivFormContainerBudgetAllocation > div
			width: 48%
	div.DivEbudgetingSummary
		display: flex
		justify-content: flex-start
		flex-wrap: wrap
		column-gap: 5%
		row-gap: $SPACE_TINY
		margin-bottom: $SPACE_LARGE
	div.DivEbudgetingDaashboadDescription
		ul
			display: flex
			flex-wrap: wrap
			justify-content: center
			column-gap: $SPACE_ENORMOUS
			row-gap: $SPACE_LITTLE
			margin-top: $SPACE_LARGE
			margin-bottom: $SPACE_LITTLE
			li
				display: flex
				margin: $SPACE_NONE
				align-items: center
				width: max-content
				div
					width: $WIDTH_LEGEND_CHART
					height: $HEIGHT_LEGEND_CHART
					margin-right: $SPACE_SMALL
					+setBorderRadius($RADIUS_LEGEND_CHART)

div.DivBodyEbudgetingApproval
	div.DivForm
		label
			span.SpanStatusHistoryApproval
				margin-left: $SPACE_LITTLE
				display: inline-block
				padding: $SPACE_NARROW $SPACE_LARGE
				+setBorderRadius($SPACE_TINY)

// E-BUDGETING - END


// BUBBLE STATUS - START

div#divStatusEpolicyMonitoring
	div.DivForm
		h3
			margin-bottom: 15px
		label#labelSubHeader
			margin-bottom: 15px
			text-align: center
		div.DivFormHorizontalContainer
			justify-content: space-around
			div.DivFormVerticalContainer
				align-items: center
				label
					width: 250px
					text-align: center
				div.DivBubleStatus, div.DivBubleStatusActive, div.DivBubleStatusNonActive, div.DivBublePublic, div.DivBubleStatusWarning
					width: 250px
					height: 70px
					display: flex
					justify-content: center
					align-items: center
					border-radius: 20px
					label
						margin: 0
						color: $THEME_TERTIARY_SOFT_COLOR
						font-weight: bold
						font-size: 2em
				div.DivBubleStatusActive
					background-color: $THEME_INDICATOR_SUCCESS_NORMAL_COLOR
				div.DivBubleStatusNonActive
					background-color: $THEME_INDICATOR_FAIL_NORMAL_COLOR
				div.DivBubleStatusWarning
					background-color: $THEME_INDICATOR_SEVERITY_LOW_COLOR
				div.DivBublePublic
					background-color: $THEME_CONVERSATION_PUBLIC_COLOR

// BUBBLE STATUS - END
